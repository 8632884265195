import React, {useState} from "react";
import axios from "axios";
import FormCard from "../../components/formCard/FormCard";
import FormCompleted from "../../components/formCard/formCompleted";
import {NameAndEmail, CompanyInfo} from "./formCard";
import FormProvider from "../../components/formCard/formContext";
//import {useNavigate} from "react-router-dom";
//import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";



const Contact = () => {
    const [formStep, setFormStep] = useState(0);
   // const {data} = useForm();
    const {handleSubmit, trigger} = useForm();
    const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);
    const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);
    const onSubmit = data => {
        console.log("finally...", data);
    }
        const SubmitForm = async (d) => {
            console.log("-- submit Form-- ")
            // Trigger validations before submitting
            const isValid = await trigger();

            if (isValid) {
                console.log("validated!", d);
                
                axios.post(process.env.REACT_APP_EXPRESS_SERVICE,{...d},{
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(apiResponse=>
                    console.log("api response=",apiResponse)
                )

            }
                await handleSubmit(onSubmit)();

            }



        return (
            <>
                <div className="flex-center">
                    <FormProvider>
                        <FormCard currentStep={formStep} prevFormStep={prevFormStep} maxSteps={1}>
                            {formStep === 0 && (
                                <NameAndEmail formStep={formStep} nextFormStep={nextFormStep}/>
                            )}
                            {formStep === 1 && (
                                <CompanyInfo formStep={formStep} nextFormStep={nextFormStep}/>
                            )}
                            {formStep === (2) && (<FormCompleted finalizeForm={SubmitForm}/>)}
                        </FormCard>
                    </FormProvider>
                </div>
            </>
        );

}
export default Contact