import React, {useEffect} from "react";
//import ReCAPTCHA from "react-google-recaptcha";
import {useForm} from "react-hook-form";
import {useFormData} from "../../../components/formCard/formContext";


import "../../../scss/index.scss";
import "../../../components/formCard/formCard.scss";
import styles from "../../../components/formCard/stylesObj.scss";

export default function NameAndEmail({formStep, nextFormStep}) {
    const {data, setFormValues} = useFormData();
    //const recaptchaRef = React.createRef();

    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue
    } = useForm({data});

    // repopulate values
    useEffect(() => {
        const doWait = () => {
            setValue("firstName", data.firstName);
            setValue("lastName", data.lastName);
            setValue("email", data.email)
        }
        setTimeout(doWait, 500);
    }, [data.firstName, data.lastName, data.email, setValue]);


    const onSubmit = (values) => {
        setFormValues(values);
        nextFormStep();
    };


    return (
        <>
            <div className={formStep === 0 ? styles.showForm : styles.hideForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* FORM ROW */}
                    <div className="formRow">

                        <div className="formRowLeft">
                            <label htmlFor="email">Email: </label>
                        </div>
                        <div className="formRowRight">
                            <div className={formStep === 0 ? styles.showForm : styles.hideForm}>

                                <input
                                    type="email"
                                    id="email"
                                    {...register("email", {
                                        required: true,
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                                    })}
                                />
                                {errors.email && (
                                    <p className={styles.errorText}>required</p>
                                )}


                            </div>

                        </div>
                    </div>
                    {/* END FORM ROW */}
                    {/* FORM ROW */}
                    <div className="formRow">
                        <div className="formRowLeft">
                            <label htmlFor="lastName">First Name</label>
                        </div>
                        <div className="formRowRight">

                            <input
                                type="text"
                                id="firstName"
                                {...register("firstName", {
                                    required: true,
                                    defaultValues: {
                                        firstName: ""
                                    }
                                })}
                            />


                        </div>
                    </div>
                    {/* END FORM ROW */}

                    {/* FORM ROW */}
                    <div className="formRow">
                        <div className="formRowLeft">
                            <label htmlFor="lastName">Last Name</label>
                        </div>
                        <div className="formRowRight">

                            <input
                                type="text"
                                id="lastName"
                                {...register("lastName", {
                                    required: true,
                                    defaultValues: {
                                        lastName: ""
                                    }
                                })}
                            />


                        </div>
                    </div>
                    {/* END FORM ROW */}


                    {/* bottom next button */}
                    <div className="formGroup padtop-1">
                        <div className="block-center">
                            <button className="app-btn" type="submit">Next</button>
                        </div>
                    </div>
                </form>

            </div>


        </>
    );
}
