import {  createSlice } from '@reduxjs/toolkit';
//createAsyncThunk,

const initialState = {
    scrollPosY : 0
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

/*
export const fetchGalleries = createAsyncThunk(
    'gallery-api/getGalleries',
    async(filter) => {
        console.log("--awaiting--", filter)
        const response = {foo:'bar'} //await getGalleries(filter);
        // The value we return becomes the `fulfilled` action payload
        console.log(">>>>", response)
        return response
    }
);
*/

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        updateY: (state,action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            //console.log("action=", action);
              // Use the PayloadAction type to declare the contents of `action.payload`
            state.scrollPosY = action.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    /*
    extraReducers: (builder) => {
        builder
            .addCase(fetchGalleries.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGalleries.fulfilled, (state, action) => {
                console.log("promise fulfilled!!! ", action.payload)
                state.status = 'loaded';
                state.viewMode = 'grid';
                state.galData = action.payload;

            });
    },
    */
});

export const { updateY } = homeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.gallery.value)`
export const getScrollY = (state) => state.scrollPosY.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
        dispatch(incrementByAmount(amount));
    }
};*/

export default homeSlice.reducer;