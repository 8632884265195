import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
//import {Col, Container, Row} from 'reactstrap';
import '../../scss/NavHeader.scss';
import logoWhite from './img/bbw-white-logo.png';
//import NavLink from './NavLink';
//import {getNewY} from './navSlice'
class NavHeader extends React.Component {

    constructor(props) {
        super(props);

        this.showNavBar = false;

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.getNewY > 100) {
            if (!this.showNavBar) {
                this.showNavBar = true;
               // document.getElementById('logoWhite').classList.remove('no-display');
               // document.getElementById('navBG').classList.remove('nav-bg-banner-retract');
              //  document.getElementById('navBG').classList.add('nav-bg-banner-extend');
            }
        } else {
            if (this.showNavBar) {
                this.showNavBar = false;
                //document.getElementById('logoWhite').classList.add('no-display');
               // document.getElementById('navBG').classList.remove('nav-bg-banner-extend');
               // document.getElementById('navBG').classList.add('nav-bg-banner-retract');
            }
        }
    }

    componentDidMount() {
        //document.getElementById('logoWhite').classList.add('no-display');
        // console.log("navheader props=",this.props);
    }

    handleHeaderClick(param, event) {
        // this.setState({nav: param})
    }


    render() {

        return (<>
            <div className="fixed-nav-bar">
                <div className="nav-bg-wrap">
                    <div id="navBG" className="nav-bg"></div>
                    <div id="logoWhite" className="logo-white">
                        <Link to={"/"} className={"nav-logo-link"}> <img alt="Ballard Web Works Logo" src={logoWhite}/></Link>
                    </div>

                </div>
            </div>
        </>);
    }
}


/**  redux boilerplate for using dispatch in a class component
 *  instead of preferred hook in function component */

const mapStateToProps = (state) => ({
        updateNav: state.navSlice.nav,
        updateNavMode: state.navSlice.navMode,
        getNewY: state.homeSlice.scrollPosY
    }
)

export default connect(mapStateToProps)(NavHeader)

