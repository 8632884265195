import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
} from "react-router-dom";
import './scss/NavHeader.scss';
import Home from './features/home/home';
import NavHeader from './features/nav/NavHeader.jsx';
import Contact from './features/contact/contact';


const NavLayout = () => {
    return (
        <>
            <div className={"fixed-nav-bar"}>
                <NavHeader/>
            </div>
            <div>
                <Outlet/>
            </div>

        </>
    );
};

const router = createBrowserRouter([
    {
        element: <NavLayout/>,
        children: [
            {
                path: "/",
                element: <Home/>
            },
            {
                path: "/contact",
                element: <Contact/>,

            }]
    },
]);

const App = () => {

    return (
        <main>
            <RouterProvider router={router}/>
        </main>
    );
}
export default App;