import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {useFormData} from "../../../components/formCard/formContext";
import styles from "../../../components/formCard/stylesObj.scss";
import "../../../scss/index.scss";

// you can use React.forwardRef to pass the ref too
const JobSelect = React.forwardRef(
    ({onChange, onBlur, name, label}, ref) => (
        <>

            <div className="formRow">
                <div className="formRowLeft">
                    <label>{label}</label>
                </div>
                <div className="formRowRight">
                    <select name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
                        <option value=""> Select a project type</option>
                        <option value="branding">Web Site - Branding</option>
                        <option value="web app">Web Site - Application</option>
                        <option value="app">Mobile App</option>
                        <option value="graphic design">Graphic Design</option>
                        <option value="product photography">Product Photography</option>
                    </select>
                </div>
            </div>

        </>
    )
);
export default function CompanyInfo({formStep, nextFormStep}) {
    const {data, setFormValues} = useFormData();

    const {
        handleSubmit,
        //formState: {errors},
        register,
        setValue
    } = useForm(data);

    // repopulate values
    useEffect(() => {
        const doWait = () => {
            setValue("jobType", data.jobType);
            setValue("company", data.company);
            setValue("jobTitle", data.jobTitle);
        }
        setTimeout(doWait, 500);
    }, [setValue, data]);

    const onSubmit = (values) => {
        setFormValues(values);
        console.log("nextFormStep=", nextFormStep)
        nextFormStep();

    };
    const onCaptchaChange = (val) => {
        console.log("capta", val);
        data.captcha = val;
    }

    const recaptchaRef = React.createRef();

    return (
        <>

            <div className={formStep === 1 ? styles.showForm : styles.hideForm}>

                <form id={"contactForm"} name={"contactForm"} onSubmit={handleSubmit(onSubmit)} netlify>

                    {/* FORM ROW */}
                    <div className="formRow">
                        <div className="formRowLeft">
                            <label htmlFor="company">Company</label>
                        </div>
                        <div className="formRowRight">

                            <input
                                type="text"
                                id="company"
                                {...register("company", {
                                    required: false,
                                })}
                            />


                        </div>
                    </div>
                    {/* END FORM ROW */}

                    <JobSelect
                        name="jobType"
                        label="Job Type"
                        {...register("jobType", {required: false})}
                    />

                    {/* FORM ROW */}
                    <div className="formRow">
                        <div className="formRowLeft">
                            <label htmlFor="jobTitle">Job Title</label>
                        </div>
                        <div className="formRowRight">

                            <input
                                type="text"
                                id="jobTitle"
                                {...register("jobTitle", {
                                    required: false,
                                })}
                            />


                        </div>
                    </div>
                    {/* END FORM ROW */}
                    <div className="formRow">
                        <div className="formRowLeft">
                            <label htmlFor="jobTitle">Human Check!</label>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
                                onChange={onCaptchaChange}
                            />
                        </div>
                    </div>

                    <div className="formGroup padtop-1">
                        <div className={"block-center"}>
                            <button className="app-btn" onClick={handleSubmit(onSubmit)}>Next</button>
                        </div>
                    </div>
                </form>


            </div>


        </>
    );
}
