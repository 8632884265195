import {useFormData} from "./formContext";
import '../../scss/index.scss';
import './formCard.scss'
import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";

export default function FormCompleted({finalizeForm}) {
    const {data} = useFormData();

    useEffect(() => {
         finalizeForm(data)
    })

    return (
        <>
            <div className={"formRow padded-1"}>


                <p>Thanks, {data.firstName}.<br/><br/>We'll get back to you soon.</p>

            </div>
            <div className={'flex-center'}><NavLink
                className={"app-btn"}
                to={'/'}
                type="button"
            >
                Thanks!
            </NavLink>
            </div>

        </>
    );
}
