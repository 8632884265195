import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
//import { Col, Container, Row } from 'reactstrap';
//import Parallax from 'parallax-js';
//import {Waypoint} from 'react-waypoint';
import ReactGA from 'react-ga';
import { updateY } from './homeSlice';
//import artistSvg from './svg/artist.svg';
//import scientistSvg from './svg/scientist.svg';
//import heroSvg from './svg/superhero.svg';
import seaplane from './img/sea-plane.png';
import bridge from './img/hero-bridge.jpg';
import bridgeMask from './img/hero-mask.png';
//import blackLogo from './img/bww-logo-black.png';
import seaplaneFront from './img/plane-front.png';
import heroGears from './img/hero-gears.png';
import iconStrategy from './img/icon-strategy.png';
import iconDev from './img/icon-dev.png';
import iconDesign from './img/icon-design.png';
//import { fade, slide } from '../../friedman/ui/uiUtils';
import '../../scss/index.scss';
//import TestLines from './lines';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPosY: window.scrollY
        };

        this.myRef = React.createRef();
        this.scrollY = window.scrollY
        this.handleScroll = this.handleScroll.bind(this);
        this.planeState = 0;
        this.titleState = 0;
        this.bgState = 0;

    }

    componentDidMount() {
        ReactGA.pageview('/Home');

        this.isLooping = true;


        //var logo = document.getElementById('logo');
        var hero = document.getElementById('hero');
        var plane = document.getElementById('seaplane');

        // fade in main bg
        hero.classList.add('fade-in-1s');

        // wait for bg fade before plane
        hero.addEventListener('animationend', () => {
            plane.classList.add('seaplane-animation');
        });
        // wait for plane before logo fade in
        plane.addEventListener('animationend', () => {
            //this.planeCleanUp();

        });


        // document.getElementById('seaplane').classList.add('seaplane-animation');
        if (window.addEventListener) {
            window.addEventListener('scroll', this.handleScroll, true);
        } else {
            window.attachEvent('onScroll', this.handleScroll, true);
        }



    }

    componentWillUnmount() {
        this.isLooping = false;
        if (window.addEventListener) {
            window.removeEventListener('scroll', this.handleScroll, true);
        } else {
            window.removeEvent('onScroll', this.handleScroll, true);
        }
    };


    planeCleanUp() {
        var plane = document.getElementById('seaplane');
        plane.removeEventListener('animationend');
        plane.classList.remove('seaplane-animation');
        plane.style.left = "100%";
        plane.style.height = "100px";
    }

    planeLaunch() {
        var plane = document.getElementById('seaplane');
        plane.classList.add('seaplane-animation');
    }



    handleClick(param, e) {
    }


    handleScroll(e) {

        var scrollDir = "down";
        this.scrollY <= window.scrollY ? scrollDir = "down" : scrollDir = "up";
        this.scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window

        //const heroSpot1 = document.getElementById('heroSpot1');
        //const heroSpot2 = document.getElementById('heroSpot2');
        //const heroSpot3 = document.getElementById('heroSpot3');
        //const homeWrap = document.getElementById('homeWrap');
        const heroBG = document.getElementById('hero');
        const planefront = document.getElementById('seaplane2');

        //const homeNav = document.getElementById('homeNav');

        // clean up elements when arriving top of page
        if (window.scrollY < 10) {
            heroBG.classList.remove('fade-0-to-60');
            //heroBG.classList.add('fade-out');
            if (this.titleState === 1) {
                this.titleState = 0;
                // heroSpot1.classList.remove('slide-from-left');
                // heroSpot2.classList.remove('slide-from-right');
                // heroSpot3.classList.remove('slide-from-left');
                //  hero.classList.remove('fade-to-60').add('fade-to-full');
            }

        }

        if (window.scrollY > 50) {
            // heroBG.classList.remove('fade-out');
            //heroBG.classList.add('fade-0-to-60');
            if (this.titleState === 0) {
                this.titleState = 1;
                //heroSpot1.classList.add('slide-from-left');
                // heroSpot2.classList.add('slide-from-right');
                //  heroSpot3.classList.add('slide-from-left');

            }
        }



        //   front view of sea plane slide in 
        if (window.scrollY > 10) {
            if (this.planeState === 0) {
                planefront.classList.remove('zero-opacity');
                planefront.classList.add('seaplane-front-slide-in');
                this.planeState = 1;
            }
        }


        // ----------------------------------
        // non-scrolling / scrolling boundary x
        if (window.scrollY < 400) {
            // homeWrap.classList.remove('home-wrap-scrollable');
            //  homeWrap.classList.add('home-wrap-fixed');
            //  homeWrap.style.top = 0;
        }

        if (window.scrollY > 400 && window.scrollY < 500) {
            // homeWrap.classList.remove('home-wrap-fixed');
            //  homeWrap.classList.add('home-wrap-scrollable');
            //homeWrap.style.top = slide(scrollDir, window.scrollY, "down", 260, 400, 120, 400);

            //homeWrap.style.top = 0;

        }

        if (window.scrollY > 860) {
            // homeNav.classList.add('home-nav-fixed');
        } else {
            // homeNav.classList.remove('home-nav-fixed');
        }


        console.log("scrollDir",scrollDir);
        // dispatch redux action to update state for scrollY for home slice of redux store
        this.props.dispatch(updateY(window.scrollY));
    }


    render() {

        return (<>

            <div id="outerWrap">

                <div className="flex-center">
                 <br/><br/><br/>
                </div>
                <div id="homeWrap" className="home-wrap">

                    <div className="home-center">

                        <div className="hero-wrap">

                            <div id="hero" className="heroBG">
                                <img alt="" src={bridge} className="hero-img" />
                            </div>

                            <div id="seaplane" className="seaplane-div">
                                <img alt="" src={seaplane} />
                            </div>

                            <div id="seaplane2" className="seaplane-front-div zero-opacity">
                                <img alt="" src={seaplaneFront} />
                            </div>

                            <div id="heromask" className="hero-mask" >
                                <img alt="" src={bridgeMask} className="hero-img" />
                            </div>

                            <div id="herogears" className="hero-gears" >
                                <img alt="" src={heroGears} className="gears-img gear-rotate" />
                            </div>

                        </div>

                    </div>

                </div>



                <div className="section">

                    <div className="content-row">

                        <div className="content-cell content-column">
                            <div className="home-text">
                                <span className="font-semi-bold big-text">We help small business leverage big tools</span><br/>

                                Ballard Web Works can help you get the most out of the web for your business. We build store fronts, web apps, and offer design and brand integration flair to take your online vision to the next level.



                            </div>

                        </div>

                    </div>
                    <div className="content-row small-box-row">
                        <div className="content-cell  small-box">
                            <div className="small-box-icon"><img src={iconStrategy} alt={"Strategy"} className="smallbox-icon-img"></img></div>
                            <div className="small-box-content small-box-title">Strategy</div>
                        </div>
                        <div className="content-cell small-box">
                            <div className="small-box-icon"><img src={iconDev} alt={"Development"} className="smallbox-icon-img"></img></div>
                            <div className="small-box-content small-box-title">Web Development</div>
                        </div>
                        <div className="content-cell small-box">
                            <div className="small-box-icon"><img src={iconDesign} alt={"Design"} className="smallbox-icon-img"></img></div>
                            <div className="small-box-content small-box-title">Design Services</div>
                        </div>
                    </div>
                </div>

                <div className="section">

                    <div className="content-row">

                        <div className="content-cell">
                            <br/>
                            <div className="home-text align-left">
                                <Link to={"/contact"}>Contact us</Link> for more information to see how Ballard Web Works can work for you.
                            </div>

                        </div>

                    </div>



                </div>


            </div>
<footer>
    Ballard Web Works, Seattle USA
</footer>

        </>
        );
    }
}

/**  redux boilerplate for using dispatch in a class component
 *  instead of preferred hook in function component */
const mapStateToProps = (state) => ({
    updateY: state.scrollPosY
})

export default connect(mapStateToProps)(Home)

