import '../../../src/scss/index.scss';
import './formCard.scss';
import './stylesObj.scss';
import React from 'react';


export default function FormCard({children, currentStep, prevFormStep, nextFormStep, maxSteps}) {

   /* const submitNext = (values) => {

        nextFormStep();
    }*/

    return (

        <div className={"formCard appForms"}>

            <div className={"flex-center"}>

            </div>

            {children}

            <div className={"block-center"}>
                {(currentStep > 0 && (currentStep <= maxSteps))  && (

                    <button
                        className={"app-btn"}
                        onClick={prevFormStep}
                        type="button"
                    >
                        Back
                    </button>

                )}

            </div>

            {currentStep < maxSteps && (<div className="block-center">
                <div className={"padtop-1"}>
                    <div>Screen {currentStep + 1} of 3</div>
                </div>
            </div>)}
        </div>


    );
}
