import { combineReducers, configureStore } from '@reduxjs/toolkit';
import homeSlice from '../features/home/homeSlice';
import navSlice from '../features/nav/navSlice';

const reducers = combineReducers({
  homeSlice, navSlice
})


export const store = configureStore({
    reducer: reducers
});